/*  Flex  */
.row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-spaced-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-spaced-start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.column-start-stretch {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.wrap {
  flex-wrap: wrap;
}

/*  Container Classes  */
.v-scroll {
  height: 100%;
  overflow-y: scroll;
}

.word-break {
  word-break: break-all;
}

.relative {
  position: relative;
}
.priority-row {
  background: var(--warn3);
  border: 2px solid var(--danger) !important;
  box-sizing: border-box;
}

.w-100 {
  width: 100%;
}
