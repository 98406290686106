:root {
  --veedPrimary: #e57a1a;
  --veedSuccess: #34a853;
  --veedDanger: #ea4336;
  --veedBlack: #001833;
  --tableHeader: #5C5C5C;
  --veedBlue: #007bff;
  --veedBG: #F8F8F8;
  --primary: #00559e;
  --primary-light: #e5eef5;
  --accent: #000000;
  --accent-light: #3e4659;
  --background: #f2f3f7;
  --background-light: #f9f9fb;
  --header: #69778c;
  --success: #008c43;
  --danger: #f44336;
  --pending: #f47100;
  --warn: #f9aa3e;
  --info: #6058e3;
  --warn2: #c18d70;
  --divider: #dce0eb;
  --border: #e2e4f0;
  --url: #30a0f8;
  --placeholder: rgba(0, 0, 0, 0.37);
  --warn3: rgba(244, 67, 54, 0.04);
  --veed-light-blue: #0077ff;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--veedBlack);
}

* {
  font-family: GilmerRegular !important;
  font-style: normal;
  font-size: 12px;
}

@font-face {
  font-family: GilmerHeavy;
  src: url('https://res.cloudinary.com/alerzo/raw/authenticated/s--3bwTcCLQ--/v1643011183/gilmer-heavy.otf');
}

@font-face {
  font-family: GilmerBold;
  src: url('https://res.cloudinary.com/alerzo/raw/authenticated/s--AHIJ8zik--/v1643011061/gilmer-bold.otf');
}

@font-face {
  font-family: GilmerLight;
  src: url('https://res.cloudinary.com/alerzo/raw/authenticated/s--9Wbw49fO--/v1643011210/gilmer-light.otf');
}

@font-face {
  font-family: GilmerMedium;
  src: url('https://res.cloudinary.com/alerzo/raw/authenticated/s--EqUESAX6--/v1643011238/gilmer-medium.otf');
}

@font-face {
  font-family: GilmerRegular;
  src: url('https://res.cloudinary.com/alerzo/raw/authenticated/s--DHi9RXE7--/v1643011262/gilmer-regular.otf');
}

@font-face {
  font-family: Gilmer;
  src: url('https://res.cloudinary.com/alerzo/raw/authenticated/s--DHi9RXE7--/v1643011262/gilmer-regular.otf');
}

.gb {
  font-family: GilmerBold;
}

.gr {
  font-family: GilmerRegular;
}

.gm {
  font-family: GilmerMedium;
}

.gl {
  font-family: GilmerLight;
}

.gh {
  font-family: GilmerHeavy;
}