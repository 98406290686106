.sm .MuiPaper-root {
    max-width: 700px !important;
}

.xs .MuiPaper-root {
    max-width: 400px !important;
}

.addUserDialog .MuiDialog-paperFullWidth {
    min-height: fit-content !important;
}

.hide-bg {
    background-color: rgba(255, 255, 255, 1);
}

.image-modal .MuiDialog-paper {
    height: 60vh;
}

.table-border table {
    border-collapse: collapse !important;
}

.MuiMenuItem-root.Mui-selected,
.MuiMenuItem-root:hover {
    background-color: var(--background) !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
    padding: 0.875rem 2rem 0.875rem 0.875rem !important;
}

.MuiPaginationItem-page:focus {
    background-color: rgba(0, 85, 158, 0.1);
}

.MuiPaginationItem-page.Mui-selected {
    color: white !important;
    background-color: var(--veedBlue) !important;
}

.MuiTab-root.MuiButtonBase-root {
    padding-inline: 20px;
    padding-block: 10px;
}

.MuiTab-root span.MuiTab-wrapper {
    text-transform: none;
    font-size: 0.875rem !important;
    font-weight: normal !important;
}

.MuiTab-root.Mui-selected span.MuiTab-wrapper {
    font-weight: bold !important;
}

.rdrDateDisplayWrapper {
    background-color: white !important;
}

.rdrDefinedRangesWrapper {
    background-color: var(--background) !important;
    margin-bottom: -6em;
}

button.rdrStaticRange {
    background-color: transparent;
}

.custom-pagination .MuiTablePagination-toolbar {
    width: 100%;
}

.custom-pagination .MuiTablePagination-selectRoot {
    border: 1px solid var(--header) !important;
    border-radius: 0.25rem !important;
    padding-bottom: 0 !important;
}

.custom-pagination .MuiTablePagination-spacer {
    display: none;
}

.custom-pagination .MuiTablePagination-selectRoot+.MuiTablePagination-caption {
    flex: 1;
    text-align: end;
}

.mr-1 {
    margin-right: 0.75rem;
}

.ml-1 {
    margin-left: 0.75rem;
}

.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 5px) !important;
}

.MuiSelect-select.Mui-disabled {
    cursor: default;
    color: var(--header);
    background-color: var(--background-light);
}

.MuiButton-contained {
    box-shadow: none !important;
}

.MuiButton-root {
    text-transform: unset !important;
}


/* Enforced styles */
.MuiAccordion-rounded:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent;
    color: inherit;
}

.MuiTableCell-stickyHeader {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background-color: inherit;
}

.MuiTableCell-root {
    text-align: left;
    line-height: 1.43;
}

.MuiAccordion-root {
    position: relative;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent !important;
    color: inherit !important;
}